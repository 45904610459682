<template>
  <a-card
    :editable="false"
  >
    <a-select
      v-if="user.role === 'admin'"
      :manual-doc="manualDoc"
      field="customer"
      :label="$t('customers.sg')"
      options="customers"
      :placeholder="$t('vehicles.newVehicle.customer.placeholder')"
    />

    <div
      v-if="selectedCustomer"
    />
    <a-select
      v-if="selectedCustomer.operators && selectedCustomer.operators.length"
      :manual-doc="manualDoc"
      field="operator"
      :label="$t('operators.sg')"
      :options="selectedCustomer.operators"
      null-option
      :placeholder="$t('vehicles.newVehicle.operator.placeholder')"
    />

    <a-select
      :manual-doc="manualDoc"
      field="vehicleType"
      :label="$t('vehicleTypes.sg')"
      :options="selectedOperatorVehicleTypes"
      :placeholder="$t('vehicles.newVehicle.vehicleType.placeholder')"
    />

    <a-input
      :manual-doc="manualDoc"
      field="number"
      :label="$t('common.number')"
      :placeholder="$t('vehicles.newVehicle.number.placeholder')"
    />

    <button
      class="button is-primary"
      @click="sendRequest"
    >
      {{ $t('common.ok') }}
    </button>

    <button
      class="button is-danger"
      @click="remove"
    >
      {{ $t('common.delete') }}
    </button>
    </div>
  </a-card>
</template>

<script>
import { orderBy } from 'lodash'


export default {
  computed: {
    selectedCustomer () {
      return this.$store.getters['customers/data'][this.doc.customer.id]
    },
    selectedOperator () {
      return this.$store.getters['operators/data'][this.doc.operator.id]
    },
    selectedOperatorVehicleTypes () {
      return this.selectedOperator ? orderBy(this.selectedOperator.vehicleTypes, ['name']) : []
    }
  },
  methods: {
    sendRequest () {
      if (!this.doc.customer || !this.doc.operator || !this.doc.vehicleType || !this.doc.number) {
        this.$buefy.dialog.alert(this.$t('common.fill_all'))
      } else {
        const otherOperatorVehicles = _.filter(this.$store.getters['vehicles/items'], vehicle => vehicle.operator.id === this.doc.operator.id && vehicle.id !== this.doc.id)
        const existingVehicle = _.find(otherOperatorVehicles, { number: this.doc.number })
        if (existingVehicle) {
          this.$buefy.dialog.confirm({
            message: this.$t('vehicles.newVehicle.confirm', { number: this.doc.number}),
            type: 'is-warning',
            onConfirm: async () => {
              console.log('clsoing existing vehicle', existingVehicle)
              this.$store.dispatch('vehicles/delete', this.doc.id)
              this.$emit('close', existingVehicle)
            }
          })
        } else {
          console.log('clsoing new vehicle', this.doc)
          this.$emit('close', this.doc)
        }
      }
    },
    remove () {
      this.$store.dispatch('vehicles/delete', this.doc.id)
      this.$emit('close', null)
    }
  }
}
</script>
