<template>
  <a-card
    :editable="editable"
  >
    <template
      #header-title
    >
      <b-icon
        icon="train-variant"
      />
      {{ doc.customer ? $t('failures.vehicle.customer', { customer: doc.customer.name }) : $t('customers.sg') }}
    </template>

    <a-value
      field="vehicle.number"
      :label="$t('vehicles.ref.number')"
    />
    <a-value
      field="vehicle.vehicleType.name"
    />
    <a-value
      field="depot.name"
    />
    <a-value
      field="operator.name"
    />
    <a-value
      field="customer.name"
    />

    <template
      #form
    >
      <a-select
        field="customer"
        options="customers"
        :to-pick="['id', 'name']"
        :required="true"
        null-option
        @change="updateCustomer"
      />

      <a-select
        v-if="doc.customer"
        field="operator"
        :options="availableOperators"
        :to-pick="['id', 'name']"
        :required="true"
        null-option
        @change="updateOperator"
      />

      <a-select
        v-if="doc.operator"
        field="depot"
        :label="$t('depots.sg')"
        :options="availableDepots"
        :to-pick="['id', 'name']"
        null-option
      />

      <type-vehicle-autocomplete
        v-if="doc.operator"
        :customer="doc.customer"
        field="vehicle"
        :options="availableVehicles"
      />

      <p
        v-if="(user.role === 'admin' || user.role === 'technician') && doc.operator"
      >
        <b-button
          class="button is-primary"
          @click="addNewVehicle"
        >
          {{ $t('failures.vehicle.add') }}
        </b-button>
      </p>
    </template>
  </a-card>
</template>

<script>
import { mapGetters } from 'vuex'
import Mixin from '@/components/Form/Mixin'
import TypeVehicleAutocomplete from '@/components/TypeVehicleAutocomplete'
import NewVehicle from '@/views/Vehicle/NewVehicle'

export default {
  components: {
    TypeVehicleAutocomplete,
    NewVehicle
  },
  mixins: [Mixin],
  props: {
    editable: {
      type: Boolean,
      default: true
    },
    field: {
      required: true
    }
  },
  computed: {
    ...mapGetters({
      customers: 'customers/items',
      operators: 'operators/items',
      vehicles: 'vehicles/items'
    }),
    availableOperators () {
      if(this.doc.customer.id) {
        const customer = this.customers.find(customer => customer.id === this.doc.customer.id)
        if(customer) {
          return customer.operators
        }
      }
      return []
    },
    selectedOperator () {
      return _.find(this.operators, operator => operator.id === this.doc.operator.id)
    },
    availableVehicles () {
      if(this.doc.operator && this.selectedOperator) {
        let vehicleTypes = _.map(this.selectedOperator.vehicleTypes, vehicleType => {
          return { vehicleType }
        })
        let vehicles = _.filter(this.vehicles, vehicle => vehicle.operator.id === this.doc.operator.id)
        let availableVehicles = _.concat(vehicleTypes, vehicles)
        console.log('updating vehicles', availableVehicles)
        return availableVehicles
      }
      console.log('updating EMPTY vehicles')
      return []
    },
    availableDepots () {
      return (this.selectedOperator && this.selectedOperator.depots) ? _.values(this.selectedOperator.depots) : []
    }
  },
  async created () {
    if (this.doc.customer) {
      await this.$store.dispatch('vehicles/fetchAndAdd', { where: [['customer.id', '==', this.doc.customer.id]], limit: 0 })
      await this.$store.dispatch('customers/fetchById', this.doc.customer.id)
    }
    if (this.doc.operator) {
      await this.$store.dispatch('operators/fetchById', this.doc.operator.id)
    }
  },
  methods: {
    async updateCustomer (value) {
      if (value) {
        // await this.$store.dispatch('operators/fetchAndAdd', { where: [['customer.id', '==', value.id]], limit: 0 })
        // let operators = this.operators.filter(operator => operator.customer.id === value.id)
        const customer = this.customers.find(customer => customer.id === value.id)
        let operators = customer.operators
        let newValue = (operators.length === 1) ? operators[0] : null
        if(newValue.id) {
          await this.$store.dispatch('operators/fetchById', newValue.id)
        }
        this.$store.dispatch('currentDoc/set', {
          field: 'operator',
          value: newValue
        })
      }
    },
    async updateOperator (value) {
      if (value) {
        await this.$store.dispatch('operators/fetchById', value.id)
        await this.$store.dispatch('vehicles/fetchAndAdd', { where: [['operator.id', '==', value.id]], limit: 0 })
      }
    },
    async addNewVehicle () {
      const self = this

      const customer = this.user.customer || this.doc.customer
      const operator = this.doc.operator
      const vehicleType = this.doc.vehicle && this.doc.vehicle.vehicleType ? this.doc.vehicle.vehicleType : null

      if (!customer || !operator) {
        this.$buefy.toast.open({
          duration: 3000,
          message: this.$t('failures.vehicle.prepare'),
          position: 'is-bottom',
          type: 'is-warning'
        })
      } else {
        const id = await this.$store.dispatch('vehicles/set', { customer, operator, vehicleType })

        this.$buefy.modal.open({
          parent: this,
          component: NewVehicle,
          hasModalCard: true,
          width: 600,
          height: '90%',
          canCancel: [],
          props: {
            manualDoc: {
              module: 'vehicles',
              id
            }
          },
          events: {
            close (newVehicle) {
              console.log('newVehicle', newVehicle)
              self.update(newVehicle)
            }
          }
        })
      }
    }
  }
}
</script>
